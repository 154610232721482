import { FC } from 'react';
// Interfaces
import { IconSvgProps } from '@utmb/types/icons';

const Facebook: FC<IconSvgProps> = ({ color, clss }) => {
    return (
        <svg className={clss} width="21" height="21" viewBox="0 0 21 21">
            <path
                d="M6 11.63V8.48h2.587c0-.734-.025-1.421.008-2.106.026-.564.06-1.142.198-1.684.401-1.58 1.629-2.554 3.355-2.663.87-.055 1.748-.01 2.652-.01v2.918h-.77c-.298 0-.596-.009-.894.003-1.033.04-1.416.445-1.426 1.509-.006.657-.001 1.314-.001 2.012h3.01l-.393 3.151h-2.617v7.99H8.587v-7.97H6z"
                fill={color}
                fillRule="evenodd"
            />
        </svg>
    );
};

export default Facebook;
